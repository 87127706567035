import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { schoolInformationAPI } from '../../api/schoolInformation';


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontSize: 12,
        lineHeight: 1.5
    },
    // headerView: {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'space-between'
    // },
    fromToView: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        
    },
    flex1: {
        flex: 1,
    },
    summaryView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '0.5px solid grey'
    },
    borderBottom: {
        borderBottom: '0.5px solid grey',
    },
    borderLeft: {
        borderLeft: '0.5px solid grey',
    },
    mt05: {
        marginTop: 5
    },
    mt1: {
        marginTop: 10
    },
    mr1: {
        marginRight: 10
    },
    mt2: {
        marginTop: 20
    },
    mt5: {
        marginTop: 50
    },
    mt10: {
        marginTop: 140
    },
    t10: {
        fontSize: 10
    },
    t17: {
        fontSize: 17,
    },
    t27: {
        fontSize: 27,
    },
    t7p5: {
        fontSize: 7.5
    },
    t7: {
        fontSize: 7
    },
    t8p5: {
        fontSize: 8.5
    },
    blackBar: {
        border: '7px solid black'
    },
    pb3: {
        paddingBottom: 5,
    },
    tcenter: {
        alignSelf: 'center',
        textTransform: 'uppercase',
    },
    p3: {
        padding: 3,
    },
    pleft10: {
        paddingLeft: 10,
    },
    w15: {
        width: '13%',
    },
    w30: {
        width: '30%',
    },
    tright: {
        textAlign: 'right'
    },
    no: {
        fontWeight: 'extrabold',
        marginTop: 5,
        marginBottom: 10
    },
    pRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    }
});

const File = ({receipt, resource}) => {
    //console.log(resource);

    const rest = receipt.balance - receipt.amount

    // loading
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState(" ")
    const [address, setAddress] = useState(" ")
    const [door, setDoor] = useState(" ")
    const [street, setStreet] = useState(" ")
    const [phoneNumber, setPhoneNumber] = useState(" ")
    const [email, setEmail] = useState(" ")

    const getInformations = () => {

    setLoading(true)

    schoolInformationAPI.getInformation()
      .then(({data}) => {
        // console.log(data.name);

        setName(data.name)
        setAddress(data.address)
        setStreet(data.street)
        setDoor(data.street)
        setPhoneNumber(data.phoneNumber)
        setEmail(data.email)

        setLoading(false)
      })
      .catch(() => {
        setName("N/A")
        setAddress("N/A")
        setStreet("N/A")
        setDoor("N/A")
        setPhoneNumber("N/A")
        setEmail("N/A")

        setLoading(false)
      })
  }

    useEffect(() => {
        getInformations()
    },[])

    return (
        <Document>
            {
                loading && (
                    <Page size="A4" style={styles.body}>
                        <Text style={styles.t10}>Chargement...</Text>
                    </Page>
                )
            }
            {
                !loading && (
                    <Page size="A4" style={styles.body}>
                        <View style={styles.blackBar}></View>
 
                        <View style={styles.mt2}>
                            <Text style={styles.t10}>{name}</Text>
                            <Text style={styles.t8p5}>{address}</Text>
                            <Text style={styles.t8p5}>{street}, {door}</Text>
                            <Text style={styles.t8p5}>{phoneNumber}</Text>
                            <Text style={styles.t8p5}>{email}</Text>
                        </View>
                        <View style={styles.pRight}>
                            <View style={styles.w30}>
                                <Text style={[styles.mt2, styles.t8p5]}>Date: {dayjs(receipt.createdAt).format('DD MMMM YYYY')}</Text>
                                <Text style={styles.borderBottom} />
                                <Text style={[styles.mt1, styles.t8p5]}>Période: {dayjs().month(receipt.yearMonthId.substr(-2) - 1).year(receipt.yearMonthId.substr(0,4)).format('MMMM, YYYY')}</Text>
                                <Text style={styles.borderBottom} />
                            </View>
                        </View>
                        <Text style={[styles.mt5, styles.t27, styles.tcenter]}>Reçu de paiement</Text>

                        <Text style={styles.no}>No: {receipt.receiptNumber}</Text>
                        <View>
                            <Text>{resource.toSchool ? 'Facturé à' : 'Payé à'}:</Text>

                            <View style={[styles.fromToView, styles.mt05]}>
                                <View style={styles.mr1}>
                                    <Text style={styles.t8p5}>Niu:</Text>
                                    <Text style={styles.t8p5}>Nom:</Text>
                                    <Text style={styles.t8p5}>Téléphone:</Text>
                                    <Text style={styles.t8p5}>
                                        {!resource.toSchool && resource.role === 'administration' ? 'Poste' : 'Classe'}
                                    </Text>
                                </View>
                                <View style={styles.flex1}>
                                    <Text style={styles.t8p5}>{resource.niu}</Text>
                                    <Text style={styles.t8p5}>{resource.name}</Text>
                                    <Text style={styles.t8p5}>{resource.phoneNumber}</Text>
                                    <Text style={styles.t8p5}>{resource.classroom}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.mt5}>
                            <Text style={styles.t10}>Sommaire</Text>
                            <View >
                                <View style={styles.summaryView}>
                                    <Text style={[styles.t8p5,styles.p3]}>Description</Text>
                                    <Text style={[styles.borderLeft, styles.p3,styles.t8p5, styles.w15, styles.tright]}>Montant</Text>
                                </View>
                                <View style={styles.summaryView}>
                                    <Text style={[styles.t8p5,styles.p3]}>Total</Text>
                                    <Text style={[styles.borderLeft, styles.p3,styles.t8p5, styles.w15, styles.tright]}>{receipt.total} FCFA</Text>
                                </View>
                                <View style={styles.summaryView}>
                                    <Text style={[styles.t8p5,styles.p3]}>Payé</Text>
                                    <Text style={[styles.borderLeft, styles.p3,styles.t8p5, styles.w15, styles.tright]}>{receipt.amount} FCFA</Text>
                                </View>
                                <View style={styles.summaryView}>
                                    <Text style={[styles.t8p5,styles.p3]}>Balance</Text>
                                    <Text style={[styles.borderLeft, styles.p3,styles.t8p5, styles.w15, styles.tright]}>{receipt.balance} FCFA</Text>
                                </View>
                                <View style={styles.summaryView}>
                                    <Text style={[styles.t8p5,styles.p3]}>Cumul</Text>
                                    <Text style={[styles.borderLeft, styles.p3,styles.t8p5, styles.w15, styles.tright]}>{receipt.total - rest} FCFA</Text>
                                </View>
                            <View style={[styles.summaryView, {border: 'none', fontWeight: 'bold', fontSize: 12}]}>
                                    <Text style={[styles.t8p5,styles.p3, styles.tright, {width: '100%', marginRight: 2, fontWeight: 'heavy'}]}>Restant: </Text>
                                    <Text style={[styles.p3,styles.t8p5, styles.w15, styles.tright]}>{receipt.balance - receipt.amount} FCFA</Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.mt5, styles.w30 ]}>
                            <Text style={[styles.mt2, styles.t8p5]}>Paiement recu par: </Text>
                            <Text style={styles.borderBottom} />
                            <View style={[styles.pleft10, styles.summaryView, {border: 'none'}]}>
                                <Text style={[styles.mt1, styles.t8p5,styles.pleft10]}>Cash: </Text>
                                <Text style={[styles.mt1, styles.t8p5,styles.pleft10]}> O </Text>
                            </View>
                            <View style={[styles.pleft10, styles.summaryView, {border: 'none'}]}>
                                <Text style={[styles.t8p5,styles.pleft10]}>Orange Money: </Text>
                                <Text style={[styles.t8p5,styles.pleft10]}> N </Text>
                            </View>
                        </View>



                        <View style={[styles.blackBar, styles.mt5]}></View>
                    </Page>
                )
            }
        </Document>
    )
}

export default File