import { colors, Grid, MenuItem, Stack, TextField } from '@mui/material'
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react'
import { paiementAPI } from '../../../api/paiement';
import { personalAPI } from '../../../api/personal';
import { yearAPI } from '../../../api/year';
import { monthOption } from '../../../config/defaultValue';
import MonthFeed from '../month/MonthFeed';
import MonthItem from '../month/MonthItem';
import PaiementList from '../PaiementList';
import { presenceAPI } from '../../../api/presence';
import { getTotalMinutes } from '../../../config/getTotalMinutes';
import LoadingBackdrop from '../../global/LoadingBackdrop';



function PersonalPaiement({niu}) {

    // console.log(niu);
    const [isLoading, setIsLoading] = useState(true)
    const [yearsList, setYearsList] = useState([])
    const [monthsList, setMonthsList] = useState([])
    const [year, setYear] = useState('')
    const [salary, setSalary] = useState(0)
    const [paiementsList, setPaiementsList] = useState([])
    const [monthTotalByMinutes, setMonthTotalByMinutes] = useState([])
    const [fixedSalary, setFixedSalary] = useState(false)
    const [receiptResourceInformation, setReceiptResourceInformation] = useState({})




    const curentMonth = dayjs().month() + 1;

    const handleYearChange = (year) =>  {
        setYear(year)
        localStorage.setItem('year', year)
        getMonths(year)
    }


    // get classroom

    const getPersonal = () => {
        setIsLoading(true)
        personalAPI.getPersonalByNiu(niu)
            .then(({data}) => {
                console.log(data);
                setReceiptResourceInformation({
                    niu: data.niu,
                    name: `${data.firstName} ${data.lastName}`,
                    phoneNumber: data.phoneNumber,
                    classroom: data.classroomId ? data.classroom?.label : data.job?.title,
                    email: data.email === '' ? 'N/A' : data.email,
                    role: data.role,
                    toSchool: false
                })

                setFixedSalary(data.fixedSalary)
                if(!data.fixedSalary) {
                    getPresences()
                }
                setSalary(data.salary)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setIsLoading(false)
            })
        
    }

    // get year from db and update state
    const getYears = () => {
        setIsLoading(true)
        yearAPI.getYear()
            .then(({data}) => {
                const _data = data.map(item => item.year)
                setYearsList(_data);

                let year = localStorage.getItem('year') ? localStorage.getItem('year') : _data[_data.length - 1]

                setYear(year)
                getMonths(year)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getMonths = (_year)  => {
        setIsLoading(true)
        yearAPI.getPersonalMonthByYearAndNiu(_year, niu)
            .then(({data}) => {
                setMonthsList(parseMonth(data))
                // console.log(data);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPaiements = ()  => {
        paiementAPI.getPersonalPaiementByNiu(niu)
            .then(({data}) => {
                setPaiementsList(data)
                // console.log(data);
            })
    }

    const getPresences = () => {
        setIsLoading(true)
        presenceAPI.getPresence(niu,year)
            .then(({data}) => {
                const monthTotalMinutes = []

                for (let index = 0; index < 12; index++) {

                    const m = data.filter((item) => dayjs(item.date).month()  === index)

                    let item = {}
                    item["key"] = index.toString();
                    item["total"] = getTotalMinutes(m);

                    monthTotalMinutes.push(item)

                }

                setMonthTotalByMinutes(monthTotalMinutes)
                //console.log(monthTotalMinutes);

            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setIsLoading(false)
            })

       
    }

    const parseMonth = (list) => list.map(data => {
        setIsLoading(true)
        // before = and correct to === 
        let month = monthOption.find(i => i.key === data.month)

        data['monthName'] = month.value

        setIsLoading(false)
        return data
    })


    // on app launch get year data from db
    useEffect(() => {
        getYears()
        getPersonal()
        getPaiements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year])

    // console.log(monthsList);
    // console.log(monthTotalByMinutes);

  return (
    <Stack>
        {
            isLoading && <LoadingBackdrop />
        }
        {
            !isLoading && (
                <Grid container>
                    <Grid item xs={10}>
                        <Grid container bgcolor='backgroundColor.main'>
                            {

                                monthsList.map(data => {

                                    let paid =  0;
                                    if(data.paiements.length > 0) {
                                        data.paiements.map(i => paid += i.amount)
                                    }

                                    let _color = null
                                    const enabled = true

                                    let total = 0
                                    if(fixedSalary) {
                                        total = salary
                                    }
                                    else {
                                        //console.log(monthTotalByMinutes);
                                        const salaryByMinutes =  salary / 60
                                        let minutes = monthTotalByMinutes.find(i => parseInt(i.key) === parseInt(data.month) - 1)

                                        //total = salaryByMinutes * (minutes?.total ? minutes?.total : 0) 
                                        total = Math.round(((salaryByMinutes * (minutes?.total ? minutes?.total : 0) ) + Number.EPSILON) * 100) / 100
                                        //console.log(minutes);
                                    }

                                    if(enabled){
                                        if(paid === total){
                                            _color = colors.green['700']
                                        }
                                        else if(paid < total)
                                        {
                                            if(data.id === curentMonth){
                                                _color = colors.yellow['800']
                                            }
                                            else {
                                                _color = colors.red['700']
                                            }
                                        }
                                    }else {
                                        _color = colors.grey['300']
                                    }

                                    return (
                                        <MonthItem 
                                            key={data.id} 
                                            paid={paid}
                                            total={total}
                                            color={_color}
                                            monthName={data.monthName}
                                            monthId={data.id}
                                            niu={niu}
                                            kind={0}
                                            getYears={() => getYears()}
                                            getPaiements={() => getPaiements()}
                                        />
                                    )
                                })
                            }
                        </Grid>
                        <Grid container mt={5}>
                            <PaiementList paiementsList={paiementsList} total={salary} resource={receiptResourceInformation} />
                        </Grid>
                    </Grid>
                    <Grid item xs={2} p={3}>
                    <Stack>
                        <MonthFeed />
                        <hr />

                        <Stack pr={2}>
                            <TextField
                                select 
                                fullWidth 
                                sx={{ mt: 2 }}
                                label="Année" 
                                size='small'
                                value={year} 
                                onChange={(event) => handleYearChange(event.target.value)}
                            >
                            {yearsList.map((year) => (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                                )
                            )
                            }
                            </TextField>
                        </Stack>
                    </Stack>
                    </Grid>
                </Grid>
            )
        }
    </Stack>
  )
}

export default PersonalPaiement